body {
  margin: 0;
  font-family: 'Nanum Gothic','S-Core', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eef1f6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
::-webkit-scrollbar {
  display: none;
  width: 20px;
}
@font-face {
  font-family: 'Nanum Gothic';
  font-weight: 100;
  src: url("asset/font/NanumGothicLight.otf") format("opentype");
}

@font-face {
  font-family: 'Nanum Gothic';
  font-weight: 400;
  src: url("asset/font/NanumGothic.otf") format("opentype");
}

@font-face {
  font-family: 'Nanum Gothic';
  font-weight: 700;
  src: url("asset/font/NanumGothicBold.otf") format("opentype");
}

@font-face {
  font-family: 'Nanum Gothic';
  font-weight: 800;
  src: url("asset/font/NanumGothicExtraBold.otf") format("opentype");
}

@font-face {
  font-family: 'S-Core';
  font-weight: 100;
  src: url("asset/font/SCDream1.otf") format("opentype");
}

@font-face {
  font-family: 'S-Core';
  font-weight: 200;
  src: url("asset/font/SCDream2.otf") format("opentype");
}

@font-face {
  font-family: 'S-Core';
  font-weight: 300;
  src: url("asset/font/SCDream3.otf") format("opentype");
}

@font-face {
  font-family: 'S-Core';
  font-weight: 400;
  src: url("asset/font/SCDream4.otf") format("opentype");
}

@font-face {
  font-family: 'S-Core';
  font-weight: 500;
  src: url("asset/font/SCDream5.otf") format("opentype");
}

@font-face {
  font-family: 'S-Core';
  font-weight: 600;
  src: url("asset/font/SCDream6.otf") format("opentype");
}

@font-face {
  font-family: 'S-Core';
  font-weight: 700;
  src: url("asset/font/SCDream7.otf") format("opentype");
}

@font-face {
  font-family: 'S-Core';
  font-weight: 800;
  src: url("asset/font/SCDream8.otf") format("opentype");
}

@font-face {
  font-family: 'S-Core';
  font-weight: 900;
  src: url("asset/font/SCDream9.otf") format("opentype");
}

@font-face {
  font-family: 'chosun';
  font-weight: 700;
  src: url("asset/font/ChosunSm.TTF") format("truetype");
}
